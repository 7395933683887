<template lang="">
    <div>
        <Bayiler/>
    </div>
</template>
<script>
    import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
export default {
    components:{
        Bayiler: () => import('@/components/bayiler/Bayiler.vue')
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Bayiler', route: '/bayiler' },
        ]);
    },
}
</script>
<style lang="">
    
</style>